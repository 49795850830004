<template>
  <div class="mt-5 alina-form p-5">
    Мемориальный комплекс "Осетровский плацдарм"
    <a href="https://oixm.ru/" target="_blank">
      Острогожского историко-художественного музея им. И.Н. Крамского
    </a>
    <br>
    Воронежская область, Верхнемамонский район, 720 км федеральной автомобильной дороги М4 «Дон».
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {}
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
